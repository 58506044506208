import React, { useEffect} from 'react'
import { Footer, Nav, Carousel, Slider } from '../Components'

import { useParams } from 'react-router-dom';

const ProjectDetail = ({ClickProject, setClickProject, data}) => {

  const {id} =useParams();
  const withoutCurrentProject = data.filter((item)=> item.id !== ClickProject.id)
  const CurrentProject = data.filter((item)=>  item.id == id)
  useEffect(() => {
   
   setClickProject(CurrentProject[0])
   
  
   
  }, [])
  

  return (
   
   <>
    <div className='mx-auto  w-full max-w-7xl '>
    <Nav />

<section className='  flex flex-col-reverse justify-start  gap-3 mb-8 md:flex-row md:justify-center '>
           <div className=' text-center flex justify-center items-center ' >
              <h3 className='text-3xl mx-4'>{ClickProject.project_name}</h3>
           </div>
           <div >
             <Carousel ClickProject={ClickProject} dotsNum={ClickProject.length} />
            </div>
       </section>  
       
         <section className='hidden  md:block'>
           <Slider title='Projects' data={withoutCurrentProject} setClickProject={setClickProject} />
          </section>
    </div>
    <Footer />
    </> 
  )
}

export default ProjectDetail