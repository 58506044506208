
import React from 'react'

import {Link } from 'react-router-dom';


const Footer = () => {
// need to  which out react icon to  only fontawesome 
// npm uninstall <name>


  return (
    <footer className=" flex items-center flex-col bg-[#221573] text-white py-8  mt-8">
                    <span className=" flex items-center gap-1 cursor-pointer" onClick={()=>{ window.scrollTo({ top:0 , behavior: 'smooth'})}}>Top <i class="fa-solid fa-arrow-up"></i></span>
                <div className=" flex gap-2 my-2 flex-col md:flex-row">
                   <Link className=" flex items-center gap-1" to='mailto:dante@safekeephomerepair.com' target='_blank'><i class="fa-solid fa-envelope"></i>: dante@safekeephomerepair.com</Link> 
                   <Link className="flex items-center gap-1" to='tel:440-624-3052'> <i class="fa-solid fa-phone"></i> : 440-624-3052</Link> 
                </div>
                <div className="">
Copyright &copy; 2024 Safekeep Home Repair
                </div>
            
        </footer>
  )
}

export default Footer

