

export default
[
    {id: 1, 
        project_name:"Bathroom", 
        length: 3 , 
        image_1: "Projects/bathroom1.jpg", 
        image_2:"Projects/bathroom2.jpg", 
        image_3:"Projects/bathroom3.jpg"},
    {id: 2, 
        project_name:"Kitchen",  
        length: 5 , 
        image_1 :"Projects/kitchen1.jpg", 
        image_2:"Projects/kitchen2.jpg", 
        image_3 :"Projects/kitchen3.jpg",  
        image_4 :"Projects/kitchen4.jpg", 
        image_5:"Projects/kitchen5.jpg"},
    { id: 3, 
        project_name:"Flooring",  
        length: 5 , 
        image_1:"Projects/flooring1.jpg", 
        image_2:"Projects/flooring2.jpg", 
        image_3:"Projects/flooring3.jpg", 
        image_4:"Projects/flooring4.jpg", 
        image_5:"Projects/flooring5.jpg"},
    {id:4, 
        project_name:"Decks", 
        length: 3 ,
        image_1:"Projects/deck1.jpg",
        image_2:"Projects/deck2.jpg",
        image_3:"Projects/deck3.jpg"},
    {id:5,
        project_name:"Deck Repair", 
        length: 1 ,
        image_1:"Projects/deckrepair.jpg"},
    {id:6,
        project_name:"Home Repairs", 
        length: 8 ,
        image_1:"Projects/homerepair1.jpg",
        image_2:"Projects/homerepair2.jpg", 
        image_3:"Projects/homerepair3.jpg",
        image_4:"Projects/homerepair4.jpg",
        image_5:"Projects/homerepair5.jpg", 
        image_6:"Projects/homerepair6.jpg", 
        image_7:"Projects/homerepair7.jpg", 
        image_8:"Projects/homerepair8.jpg" },
    {id:7,
        project_name:"Countertop", length: 2 ,
        image_1:"Projects/countertop1.jpg",
        image_2:"Projects/countertop2.jpg"},
    {id:8,
        project_name:"Tub", 
        length: 2 ,
        image_1:"Projects/tubs1.jpg",
        image_2:"Projects/tubs2.jpg"}
]