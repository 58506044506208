import React, {useState, useRef, useEffect} from 'react'
import ProjectCard from './ProjectCard'




const Slider = ({title, data, setClickProject}) => {
    

   
    const containerRef= useRef(null);
    const TRANSLATE_AMOUNT = 160;
    const [ translate, setTranslate]= useState(0)
    const [isLeftVisible, setIsLeftVisible]= useState(false)
    const [isRightVisible, setIsRightVisible]= useState(false)



     useEffect(() => {
       
        if(containerRef.current ==null) return

        const observer = new ResizeObserver(entries =>{
            const container =entries[0]?.target
            if(container == null)return
            setIsLeftVisible(translate > 0)
            console.log(`${translate + container.clientWidth} < ${container.scrollWidth}`)
            setIsRightVisible(translate + container.clientWidth < container.scrollWidth)
        })

        observer.observe(containerRef.current)

        return () =>{
 observer.disconnect()
        }

     }, [translate])
     



  return (
    <>
    <h3 className='text-2xl text-center'>{title}</h3>
    <div className='max-w-[1480px] h-full w-full m-auto py-16  relative overflow-x-hidden  px-8' ref={containerRef}>
        
        <div  className='w-full h-full  rounded-2xl bg-center bg-cover duration-500'>
                 {/* content- need 2 extra */}
       
                 <div className=' flex gap-2 whitespace-nowrap transition-transform w-[max-content]'  style={{ transform: `translateX(-${translate}px)`}}>
        { data.map((item)=>(<ProjectCard data={item} key={item.id} setClickProject={setClickProject} />))}
        {new  Array(4).fill(0).map((_, index) => (<ProjectCard />))}
         </div>
            
        
        
        {/* Left  arrow  44:49 on youtube */}
        {isLeftVisible  && (<div className=' absolute top-[-21.5%] -translate-x-0 translate-y-[50%] left-0 text-5xl text-black cursor-pointer bg-gradient-to-r from-50% from-white to-transparent  '>
            <button className=' p-2 h-80 w-24'>
            <i class="fa-solid fa-chevron-left" onClick={()=>{
                setTranslate(translate =>{
                    const newTranslate= translate -TRANSLATE_AMOUNT
                    if (newTranslate <=0)  return 0
                    return newTranslate
                    
                })
            }} ></i>
            </button>
        </div>)}
        

         
    
        {/* right  arrow  */}
        {isRightVisible && (
            <div className=' absolute top-[-21.5%] -translate-x-0 translate-y-[50%] right-0 text-5xl   text-black cursor-pointer bg-gradient-to-l from-white from-50% to-transparent  flex justify-center'>
        <button className=' p-2 h-80 w-24 '>
        <i class="fa-solid fa-chevron-right" onClick={()=>{
                setTranslate(translate =>{
                    if(containerRef.current == null) {
                        return translate}
                    const newTranslate= translate +TRANSLATE_AMOUNT
                    const edge = containerRef.current.scrollWidth
                    const width = containerRef.current.clientWidth
                    
                    if (newTranslate + width >= edge){
                        return edge-width
                    }
                    return newTranslate
                    
                })
            }} ></i> 
        </button>
            
            
        </div>)}
        
        

            </div>
        </div>
    </>
  )
}

export default Slider