
import React from 'react';
import { motion } from 'framer-motion';
import {useNavigate} from 'react-router-dom';


const ProjectCard = ({data, setClickProject}) => {
  let navigate= useNavigate();
 
 
 return (
<>
    {data? (<motion.div  

    onClick={()=>{ 
      setClickProject(data)
      navigate(`/${data.id}`)
      window.scrollTo({ top:0 , behavior: 'smooth'})
    }}
   
      whileHover={{ scale: 0.9 }}
    whileTap={{ scale: 0.7 }}
    className=' w-80  h-80 mx-auto cursor-pointer' >
      <div style={{backgroundImage:`url(${data.image_1})`}} className='w-full h-full rounded-2xl bg-center bg-cover  duration-500 flex  flex-col justify-end items-center'>
        
        <div className=' flex flex-col justify-center items-center w-[320px]'>
        <span className='text-lg text-wrap text-center  bg-white/50 mb-4 px-4 '>{data.project_name}</span>
        </div>
        
      </div>
  </motion.div> ) :(<div  className=' w-80  h-80 mx-auto' >
    <div className='w-full h-full rounded-2xl bg-center bg-cover duration-500 flex  flex-col justify-end items-center'>
    <h3 className='text-2xl'>{""}</h3>
    <span>{""}</span>
    </div>
</div>) }
</>
  )
}

export default ProjectCard