import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import {  motion,  } from "framer-motion";
import {Link } from 'react-router-dom';


const Modal = ({setToggleModal,toggleModal }) => {
 
 
  const [formDisplay, setFormDisplay] = useState("normal")
 
 
  
 function contact(event ){
   event.preventDefault()
   setFormDisplay("loading")

   emailjs.sendForm(
     'service_qm47ewr',
     'template_944sgtp',
    event.target, 
    '_43HFqhr843RLsncp'
    ).then(()=>{
      setFormDisplay("success")
    }).catch(()=>{
      
      setFormDisplay("failed")
    })  
  }
  
  return (
    
    <motion.div className='bg-[#221573] text-white z-10  p-4 shadow-xl absolute top-[30%]   w-[350px]  h-[450px] md:left-[15%]' initial={{opacity:0}}  animate={{opacity:1}} exit={{opacity:0}} transition={{duration: 0.5, delay: 0.1}}>
    <div>
      <div className=' cursor-pointer' onClick={()=> {setToggleModal(!toggleModal) 
    setFormDisplay('normal')}}>
      <i class="fa-solid fa-x text-3xl" ></i>
      </div>
    
    { formDisplay=== "normal" && (
       <form id="contact__form" className=''  onSubmit={contact}>
        <span>* mean required</span>
       <div className=' mb-4'>
         <label className=' text-lg' for="name">Name*: </label>
         <input className=' text-black px-1' placeholder='Joe' name="user_name" type="text" required></input>
       </div>
       <div className=' mb-4'>
         <label className='text-lg' for="phone">Phone Number: </label>
         <input className='text-black px-1 ' placeholder="123-456-7891" name="user_phone" type="tel" ></input>
       </div>
       <div className='mb-4 '>
         <label className='text-lg' for="email">Email*: </label>
         <input className='px-1 text-black' placeholder= "Joe@email.com" name="user_email" type="email" required ></input>
       </div>
       <div className='mb-4  '>
         <label className='text-lg' for="message">Message*: </label>
         <textarea className='w-full px-1 text-black h-[100px] 'placeholder= "Hello, I would like...." name="message" type="text" required ></textarea>
       </div>
       <button id="contact__submit" className='bg-white text-[#221573] p-2 text-lg'>Send Message</button>
       </form>
    )}
    { formDisplay==="loading" && (
      <div className=' flex items-center justify-center my-16 '>
      <motion.i inital={{ rotate: "0deg"}} animate={{ rotate: "360deg",  transition: { repeat: Infinity, duration: 1 }}} className="fa-solid fa-spinner text-8xl"></motion.i>
      </div>
    )
    }  
    { formDisplay === "success" &&(
         <div className='text-xl flex items-center  text-center flex-col my-16'>
         <span>Thanks for the message!</span>
         <span>Looking forward to speaking to you soon!</span>
         </div>
    )
    }
    {
       formDisplay === "failed" &&(
        <div className='text-xl flex items-center  text-center flex-col my-16'>
         <span>The email service is temporary unavailable. Please contact us directly at</span>
         <Link className=" underline" to='mailto:dante@safekeephomerepair.com' target='_blank'> dante@safekeephomerepair.com</Link> 
         </div>
       )
    }
       
    </div>
    </motion.div>
  
  )
}

export default Modal