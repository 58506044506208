import React from 'react'
import { Footer, Nav, ProjectCard } from '../Components'


const Projects = ({setClickProject, data}) => {
  return (
    <>
    <div className='mx-auto  w-full max-w-7xl '>
    <Nav />
    
   <section className='grid grid-cols-[repeat(auto-fit,_minmax(300px,_1fr))]   gap-6 items-start justify-start my-8 mx-auto \ '>
   { data.map((item)=>(<ProjectCard data={item} key={item.id} setClickProject={setClickProject} />))}
   </section>
   
    </div>
    <Footer />

</>
  )
}

export default Projects