

import React, { useState } from 'react'
import logo  from "../assests/Dante's logo2.png"
import {Link } from 'react-router-dom';
import {  motion, AnimatePresence  } from "framer-motion"

const Nav = () => {
  
  const [menu, setMenu] = useState(false)
  
  
  return (
    <nav className='flex justify-between items-center mt-4   ' >
<figure className=' max-w-[220px]'><img  className=" object-contain" src={logo} alt='logo'></img></figure>
<ul className=" justify-between gap-6 hidden  md:flex"> 
<Link className="text-3xl hover:text-yellow-300" to="/" >Home</Link>
  <Link className="text-3xl hover:text-yellow-300" to="/projects" >Projects</Link>
  <Link className="text-3xl hover:text-yellow-300" to="/services">Services</Link>  
</ul>
<button><i className="fa-solid fa-bars text-6xl mr-4 block md:hidden" onClick={()=>setMenu(!menu)}></i></button>
<AnimatePresence>
{ menu && (
  
  <motion.div 
  className=' absolute bg-white w-full top-1 left-1 py-8 flex flex-col shadow-md items-center '
  initial={{opacity:0}}  animate={{opacity:1}} exit={{opacity:0}} transition={{duration: 0.5, delay: 0.1}}
  >
    <button onClick={()=>setMenu(!menu)}><i class="fa-solid fa-x text-3xl"></i></button>
  <ul className="  flex flex-col mt-4 gap-2 "> 
  <Link className="text-3xl hover:text-yellow-300" to="/" >Home</Link>
  <Link className="text-3xl hover:text-yellow-300" to="/projects" >Projects</Link>
  <Link className="text-3xl hover:text-yellow-300" to="/services">Services</Link>  
</ul>
  </motion.div>
  
)
}
</AnimatePresence>
</nav>
  )
}

export default Nav


