import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, Projects, Services, ProjectDetail } from "./pages";
import { useState } from 'react';
import data from "./projectdata.js"






function App() {
   const [ClickProject, setClickProject ]= useState("")


  return (
  <Router>
    <Routes>
        <Route  path='/' element={<Home setClickProject={setClickProject} data={data} />} />
        <Route  path='/projects' element={<Projects setClickProject={setClickProject}  data={data}/>} />
        <Route  path='/services' element={<Services setClickProject={setClickProject}  data={data}/>} />
        <Route path= ":id" element={<ProjectDetail ClickProject={ClickProject} setClickProject={setClickProject} data={data} />} />
    </Routes>
  </Router>


  );
}

export default App;