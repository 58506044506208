import React from 'react'
import { Footer, Nav, Slider } from '../Components'


const Services = ({setClickProject, data}) => {
  return (
    <>
    <div className='mx-auto  w-full max-w-7xl '>
    <Nav />
   <section className='text-center my-8 mx-1'>
        <h1 className='text-5xl mb-4'>Here's Our Services</h1>
        <h3 className='text-3xl mb-2'>Minor Home Repair, Drywall, Cabinet</h3>
      
        <h3 className='text-3xl'>Floor Installation</h3>
        <ul className='text-xl'>
            <li>Laminate</li>
            <li>Vinyle</li>
            <li>Tile</li>
            <li>Carpet</li>
        </ul>
        <h3 className='text-3xl'>Light Plumbing</h3>
        <ul className='text-xl'>
            <li>toilet install</li>
            <li>faucet install</li>
        </ul>
        <h3 className='text-3xl'>Light Electrical</h3>
        <ul className='text-xl'>
            <li>Light</li>
            <li>Fixtures</li>
            <li>Receptacle</li>
            <li>Light Switch</li>
            <li>General Mounting <span className='itlaic'>(T.V., Shelves, Cabinet)</span></li>
        </ul>
   </section>
   <section className='hidden  md:block '>
    <Slider title='Projects' data={data} setClickProject={setClickProject} />
   </section>
    </div>
    <Footer />

</>
  )
}

export default Services