import React, { useState } from 'react'
import { Footer, Modal, Nav, Slider } from '../Components'
import  placeholder from '../assests/animation-placeholder.jpg'

import profilePhoto from '../assests/fakedante.jpg'

import {  AnimatePresence,motion } from 'framer-motion'

const Home = ({setClickProject, data}) => {
        const [ toggleModal, setToggleModal] = useState(false)
        

    return (
    <>
        <div className='mx-auto  w-full max-w-7xl  '>
        
        <Nav />
       
        
        <section className='mt-4 flex flex-col justify-around items-center mx-4 md:flex-row '>
            <AnimatePresence>
            {toggleModal && <Modal setToggleModal={setToggleModal} toggleModal={toggleModal} />}
            </AnimatePresence>
            <div className='flex flex-col items-center gap-4 '>
            <h1 className=' text-4xl text-center  md:text-5xl '>Can't do it, don't want to do it, Safekeep Home Repair will do it.</h1>
            <motion.button
            onClick={()=> setToggleModal(!toggleModal)}
            whileHover={{ rotate: '10deg' }}
            whileTap={{ scale: 0.9 }}
            className="bg-[#221573] text-white p-2 text-lg mb-4">Ask For A Quote</motion.button>
            </div>
            <figure className=' max-w-[800px]'>
             <img src={placeholder} alt="worker working on home" className=' w-full block  md:hidden' />
             <div className='hidden md:block '>
    <dotlottie-player src="https://lottie.host/32627f02-a437-4de2-8782-735ab4100d06/9bxbFC7fsf.json" background="transparent" speed="1"  loop autoplay className='w-full '></dotlottie-player>
    </div>
            </figure>
        </section>
        <section className='flex-col justify-center items-center m-8 hidden  md:flex'>
            <Slider title="Projects" data={data} setClickProject={setClickProject} />
        </section>
        <section className='flex flex-col-reverse justify-between items-center mx-4 mt-4  md:flex-row'>
            <figure className=' max-w-md mr-2 '>
                <img src={profilePhoto}  alt="dante" className=' w-full' />
            </figure>
            <div className='flex flex-col items-center gap-4 px-3 '>
                 <h2 className='text-4xl'>Safekeep Home Repair is a handyman services.</h2>
                <p className='text-lg'> Provide services in Cuyahoga, Lake, Geoiga County. We have a widespread of service we provide, appliance repair, small projects repair, security systems installion.</p>
            </div>
        </section>
        </div>
        <Footer />

    </>
  )
}

export default Home