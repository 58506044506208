import React, {useEffect, useState} from 'react'


const Carousel = ({ClickProject, dotsNum}) => {
    const cards=[{wallpaper:ClickProject.image_1},
    {wallpaper: ClickProject.image_2 },
    {wallpaper: ClickProject.image_3  },
    {wallpaper: ClickProject.image_4  },
    {wallpaper: ClickProject.image_5  },
    {wallpaper: ClickProject.image_6  },
    {wallpaper: ClickProject.image_7  },
    {wallpaper: ClickProject.image_8  },
    ]
    
    const imagesArray= new  Array(dotsNum).fill(0)
    
     const  [currentIndex, setCurrentIndex] = useState(0)

    const  prevSlide = () => {
         const  isFirstSlide= currentIndex=== 0
          const newIndex= isFirstSlide? imagesArray.length -1 : currentIndex -1;

           setCurrentIndex(newIndex)
    }
    const  nextSlide = () => {
        const  isLastSlide= currentIndex=== imagesArray.length-1
        const newIndex= isLastSlide? 0 : currentIndex +1;

         setCurrentIndex(newIndex)
    }
     const goToSlide = (slideIndex) =>{
        setCurrentIndex(slideIndex)
     }


     useEffect(() => {
       setCurrentIndex(0)
     },  [ClickProject])
     
  return (
    <div className=' h-[700px]   min-w-[200px]  w-full  py-16 px-4 relative md:min-w-[600px]'>
        
        <div style={{backgroundImage: `url(${cards[currentIndex].wallpaper})`}} className='w-full h-full  rounded-2xl bg-center bg-cover bg-no-repeat duration-500'>
        {/* Left  arrow   */}
        <div className=' absolute top-[50%] -translate-x-0 translate-y-[50%] left-5 text-2xl r rounded-full p-2 bg-black/20 text-white cursor-pointer'>
            <i class="fa-solid fa-chevron-left" onClick={prevSlide} ></i>
        </div>

        {/* right  arrow  */}
        <div className=' absolute top-[50%] -translate-x-0 translate-y-[50%] right-5 text-2xl r rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <i class="fa-solid fa-chevron-right" onClick={nextSlide} ></i>
        </div>
        

        </div>
        <div className='flex top-4 justify-center py-2'>
            { new  Array(dotsNum).fill(0).map((card, cardIndex) => (
                <div key={cardIndex} style={{opacity:cardIndex=== currentIndex? "1" :"0.5", transition: "opacity 1s" }} onClick={()=> goToSlide(cardIndex)} className='text-2xl cursor-pointer mx-2'>
                    <i className="fa-solid fa-square"></i>
                     </div>
            ))}
        </div>
    </div>
  )
}

export default Carousel